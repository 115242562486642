import {Component, Inject, Vue} from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import DfUser from "@/vue/domain/user/df-user";
import DfBrand from "@/vue/domain/brand/df-brand";
import DfContent from "@/vue/domain/content/df-content";
import {CONTAINER_TYPES} from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import moment from "moment";

const DfHeaderMobileComponent = () =>
  Utils.externalComponent2("df-header-mobile");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfStoreCard: DfStoreCardComponent,
  },
  filters: {
    dateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
    },
    price: (value: string, unit: string) => {
      unit = unit ? `${unit}` : "";
      const result: string = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR",
      }).format(parseFloat(value) || 0);
      if (unit == "") {
        return `${result}`;
      } else if (unit == "KG" || unit == "L") {
        return `${unit} ${result}`;
      } else {
        return `${unit}`;
      }
    },
  },
})
export default class DfPageAccountCardCashback extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private cashback: any = {
    total: "",
    date: "",
    hour: "",
  };
  private working = false;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get currentUser(): DfUser {
    return this.$store.getters.user;
  }

  get cashbackBackgroundImage(): any {
    return Utils.getPropertyImageUrl(
      this.brand,
      Utils.PROPERTY_CASHBACK_MY_AREA_BACKGROUND_IMAGE,
      ""
    );
  }

  get cashbackFontcolor(): any {
    return Utils.getPropertyValue(
      this.brand,
      Utils.PROPERTY_CASHBACK_MY_AREA_FONT_COLOR,
      "STRING", "black"
    );
  }

  private goToStorePage(store: DfStore) {
    this.$router
      .push({ name: "store", params: { storeAlias: store.alias } })
      .catch((err) => err);
  }

  beforeMount() {
    if (Utils.showPrivateAreaCashback()) {
      this.loadUserContents();
    } else {
      this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
    }
  }

  private openMap() {
    window.open(
      `${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(
        this.currentStore
      )}`,
      "_blank"
    );
  }

  private loadUserContents() {
    if (this.currentUser) {
      this.working = true;
      this.service
        .getUserContents({ typeCode: ["CBK"] })
        .then((data: any) => {
          const cashbacks: Array<DfContent> = data.userContents
            .filter((content: DfContent) => content.type.code === "CBK")
            .map((content: DfContent) => {
              const date: string = Utils.getPropertyValue(content, Utils.PROPERTY_PRIVATE_CASHBACK_LAST_UPDATE, "DATE");
              const total: string = Utils.getPropertyValue(content, Utils.PROPERTY_PRIVATE_CASHBACK_MONEY_BALANCE, "NUMBER");
              return {
                total: new Intl.NumberFormat(navigator.language, {
                  style: "currency",
                  currency: "EUR",
                }).format(parseFloat(total) || 0),
                date: date ? moment(date, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "",
                hour: date ? moment(date, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_HOURS_MINUTES_SECONDS) : "",
              };
            });
          this.cashback = cashbacks.length ? cashbacks[0] : this.cashback;
          this.working = false;
        })
        .catch((error: Error) => {
          this.working = false;
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }
}
